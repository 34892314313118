<template>
  <header class="border-b border-gray-200 p-4 bg-white dark:bg-primary-700 dark:text-primary-50">
    <nav class="flex items-center justify-between">
      <div class="flex items-center">
        <div v-for="(link, index) in navLinks" :key="index">
          <router-link :to="link.route">{{ link.name }}</router-link>
          <i v-if="index < navLinks.length - 1" class="pi pi-chevron-right mx-2"></i>
        </div>
      </div>
      <!-- <div>
        <i class="pi pi-clone mx-2" style="font-size: 24px"></i>
      </div> -->
      <div v-if="contextMenu">
        <i class="pi pi-ellipsis-v mx-2 cursor" style="font-size: 20px" @click="onRightClick"></i>
        <ContextMenu ref="menu" :model="contextMenu">
          <template #item="{ item, props }">
              <a v-ripple class="flex items-center p-2" :href="item.url" :target="item.target" :disable="item.disabled" v-can="item.permissions" v-role="item.roles" v-if="item.url">
                  <span :class="item.icon" style="font-size: 12px"/>
                  <span class="ml-2">{{ item.label }}</span>
              </a>
              <a v-ripple 
                class="flex items-center p-2" 
                :disable="item.disabled" 
                v-can="item.permissions" 
                v-role="item.roles" 
                @click.prevent="sendCommand(item?.jsfunction)" 
                v-if="item?.jsfunction">
                  <span :class="item.icon" style="font-size: 12px"/>
                  <span class="ml-2">{{ item.label }}</span>
              </a>
          </template>
        </ContextMenu>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import type { LocationQueryValue } from "vue-router";
import type { MenuItemCustom } from "~/types/MenuItemCustom";
import Role from "~/types/Role";
import Permissions from "~/types/Permissions";

interface NavLink {
  route: string;
  name: LocationQueryValue | LocationQueryValue[] | string;
}

const props = defineProps({
  navLinks: {
    type: Array as () => NavLink[],
    required: true,
  },
  contextMenu: {
    type: Array as () => MenuItemCustom[],
    required: false,
  },
});

const emit = defineEmits<{
  (e: "emitFunction", jsfunction: string): void;
}>();

const sendCommand = (jsfunction: string) => {
  console.log("sendcommand", jsfunction);
    emit("emitFunction", jsfunction);
};

const menu = ref();
const onRightClick = (event) => {
    menu.value.show(event);
};
</script>